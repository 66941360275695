/* OTPInput.css */


  
  /* Style the OTP input fields and label row */
  .custom-otp-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  /* Style the OTP input fields */
  .fields-row {
    display: flex;
    justify-content: center;
  }
  
  .fields-row input {
    width: 40px;
    height: 40px;
    margin: 5px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Style the OTP label */
  .label-row h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  