@keyframes meeting {
  from {
    opacity: 1; }
  to {
    opacity: 0.8; } }

.meeting-bar {
  background: #37ab56;
  color: #f7f7f7;
  height: 50px;
  cursor: pointer;
  animation-name: meeting;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out; }
