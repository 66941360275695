.msg-sync-modal-container{
    margin-top: 10%;
    width: min-content;
    background-color: white;
    z-index: 99;
}

.userimage{
    height: 8vh;
}

.sync-loader{
    height: 10vh;
}

.magmee_migrated_datetime{
    font-size: 12px;
    color: grey;
    font-style: italic;
}

.msgmee_migrated_text{
    font-size: 12px;
    color: greenyellow;
    font-style: italic;
}