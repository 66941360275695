.group-create {
  height: 100%;
  flex-grow: 1;
  border-right: 1px solid rgba(218, 220, 224, 0.5);
  display: flex;
  flex-direction: column;
  .notice {
    font-size: 12px;
    color: #666;
    text-align: center;
    padding: 18px; }
  .rooms {
    flex-grow: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; } }

@media screen and (min-width: 1200px) {
  .group-create {
    max-width: 360px;
    min-width: 360px; } }

@media screen and (min-width: 700px) and (max-width: 1199px) {
  .group-create {
    max-width: 300px;
    min-width: 300px; } }

@media screen and (max-width: 699px) {
  .group-create {
    width: 100%; } }

.theme.light .group-create {
  background: #fff; }

.theme.dark .group-create {
  background: #303841; }

.active {
  color: #37ab56 !important; }

.selection-text {
  text-align: center;
  padding: 12px;
  font-size: 14px;
  background: #f7f7f8;
  color: #666; }

.selection-text.error {
  color: #37ab56; }

.create-button {
  background: #37ab56;
  width: 100%; }

.create-button:hover {
  background: #37ab56;
  opacity: 0.9; }
