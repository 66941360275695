.msgmee-login-container{
  max-width: 320px;
  position: absolute;
  right: 20%;
  top:10%;
  border: 2px solid black;
  border-radius: 10px;
  padding: 20px;
  
}
.msgmee-login-container h1 {
  margin-bottom:0;
}

.msgmee-login-container form {
  margin-top:5px;
}

.get-otp-btn{
  margin-top: 10px;
  background-color: #81c14b !important;
  color: white !important;
}

.login-or{
    text-align: center;
}


.login-form-phone{
  margin-top: 40px;
}

.login-term-text p{
  margin: 0px;
  padding: 0;
}
.login-term-text a{
  color: rgb(0, 81, 255);
}
.login-term-text a:hover{
  color: darkblue;
}

.otp-label{
  display: flex;
  justify-content: space-between; /* Add space between labels */
  align-items: center; /* Vertically center the labels */
}

.otp-label h4 , h5{
  margin: 0;
}

.otp-label h5 {
  color: rgb(0, 81, 255);
  cursor: pointer;
}

.otp-label h5:hover{
  color: darkblue;
}

.msgmee-login-fields-container{
  
}

@media (max-width: 600px) {
  .msgmee-login-container {
    /* Styles specific to screens less than 600px wide */
    /* Add your responsive styles here */
    right: 0;
    left: 0;
  }
}